

.form-input{
    line-height: 35px;
    font-size: 20px;
    text-indent:8px;
    box-sizing: border-box;
    border-radius: 1px;
    width:100%;
    outline-width: 0;
    margin-top: 6px;
    margin-bottom: 0px;
}

.form-input:focus { 
    border: 1px solid rgb(165,183,234);
    border-radius: 1px;
}

.form-input:optional {
    border: 1px solid rgb(165,183,234);
    background-color: white;
}

.form-input:required {
    border: 1px solid rgb(45, 95, 233);
    background-color: white;
}

.form-input:required:focus:invalid {
    border: 1px solid red;
    background-color: rgb(253 239 241);
}

.form-button{
    background-color: rgb(91,126,224);
    width:100%;
    height:40px;
    margin-bottom:7px;
    border-radius:8px;
    border-color: rgb(91,126,224);
    border-style: solid;
    float:right;
    transition: background-color 0.4s, border-color 0.4s;
}

.form-button:hover{
    background-color: rgb(45, 95, 233);
    border-color: rgb(45, 95, 233);
}

@media only screen and (min-width: 850px) {
    .form-button{
        width:200px;
    }
}


.singleline{
    
}

.multiline{
    
}

.number{
}

