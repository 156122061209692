.feature-header{
    height: 100vh;
    position: relative;
    background-color: rgb(14 24 33);
    z-index:2;
}

.header-wrapper{
    overflow: hidden;
}

.heading{
    display: flex;
    align-items: center;
    height: 80%;
    width:40%;
    margin:auto;
    text-align: center;
}

.header-name-container{
    height:15%;
    width:500px;
    background-color: rgb(14 24 33);
    display: flex;
    align-items: center;
    position:absolute;
    bottom:0%;
    transform: skew(25deg);
    margin-left:-70px;
    padding-left:50px;
    z-index:3;
}

.header-name{
    text-align: center;
    margin:auto;
    font-size: 40px;
    transform:skew(-25deg);
}

@media only screen and (min-width: 850px) {
    .header-name-container{
        height:20%;
        margin-left:-50px;
    }
}

.left-header-text-container{
    background-color: rgb(14 24 33);
    display: flex;
    align-items: center;
    height:100%;
    position:absolute;
    left:-75%;
    transform:skew(25deg);
    width:120%;
    z-index:2;
}

.left-header-text{
    transform:skew(-25deg);
    padding-top:75%;
    font-size:35px;
    position:absolute;
    right:-10%;
}

.left-header-image{
    width:auto;
    height:100vh;
    position: absolute;
    top:0;
    right:-80%;
    z-index:1;
    margin-left:auto;
    margin-right:auto;
    display:block;

}

@media only screen and (min-width: 850px) {
    .left-header-text-container{
        height:100%;
        width:60%;
        left:-30%;
    }

    .left-header-text{
        padding-right:15%;
        padding-top:0%;
        right:0%;
        font-size:40px;
    }

    .left-header-image{
    	width:100vw;
	height:auto;
	right:0;
    }
}


.right-header-text-container{
    background-color: rgb(14 24 33);
    display: flex;
    align-items: center;
    width:120%;
    height:100%;
    position:absolute;
    right:-90%;
    transform:skew(25deg);
    z-index:2;
}

.right-header-text{
    transform:skew(-25deg);
    padding-left:0%;
    padding-bottom:90%;
    font-size:35px;
    margin-left:-10%;
}

.right-header-image{
    height:100vh;
    width:auto;
    position: absolute;
    top:0;
    left:-80%;
    z-index:1;
    margin-left:auto;
    margin-right:auto;
    display:block;

}

@media only screen and (min-width: 850px) {
    .right-header-text-container{
        height:100%;
        width:60%;
        right:-30%;
        padding-right:50px;
    }

    .right-header-text{
        padding-left:15%;
        padding-bottom:0%;
        left:0%;
        font-size:40px;
        margin-left:0%;
    }

    .right-header-image{
    	width:100vw;
	height:auto;
	left:0;
    }
}

.down-arrow{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 70%;
    font-size: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    margin: auto;
}
