
html {
    scroll-behavior: smooth;
}

  
.home-page{
    width: 100vw;
    height:100%;
    background-color: rgb(14 24 33);
    color: rgb(165,183,234);
    font-family: Raleway;
    overflow-x: hidden;
    position:relative;
}
