.video-player-wrapper{
    width:100%;
    height:100%;
}

.video-player{
    padding-top: 56.25%;
    position: relative;
}

.video-player > div {
    position: absolute;
    top:0;
    left:0;
}

.video-thumbnail{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-play-circle{
    padding:auto;
    color:whitesmoke;
}
