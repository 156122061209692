
.cross-icon{
    width:40px;
    height:40px;
    display: block;
    z-index:10;
}

.cross-line{
    width:35px;
    height:5px;
    margin-bottom:6px;
    background-color: rgb(91,126,224);
    transition: width 0.2s height 0.2s background-color 0.2s;
}

.cross-icon:hover .cross-line{
    width:36px;
    height:6px;
    background-color: whitesmoke;
}

.down-bar{
    transform:translate(0px, 0px) rotate(45deg);
    transition: transform 0.2s;
}

.cross-icon:hover .down-bar{
    transform:translate(0px, -1px) rotate(55deg);
}

.up-bar{
    transform:translate(0px, 11px) rotate(-45deg);
    transition: transform 0.2s;
}

.cross-icon:hover .up-bar{
    transform:translate(0px, 11px) rotate(-55deg);
}
