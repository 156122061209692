.spacer{
    width:100%;
    height:80vh;
    position:relative;
    z-index:1;
}

.spacer-img{
    width:100%;
    height:100%;
    object-fit: cover;
}

.empty-spacer{
    width:100%;
    height:100%;
    opacity:0%;
}