
.profile-page{
    width:100%;
    min-height:110vh;
    background-color: rgb(14 24 33);
    position: relative;
    z-index:2;
}

.profile-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    padding:40px;
    color:rgb(91,126,224);
    text-align: center;
}

.profile-body{
    width:100%;
    margin:auto;
    padding:5%;
    padding-bottom: 10%;
    padding-top:0px;
    min-height:175vh;
}

@media only screen and (min-width: 850px) {
    .profile-body{
        padding-left:10%;
        padding-right:10%;
        min-height:100vh;
    }
}

@media only screen and (min-width: 1440px) {
    .profile-body{
        padding-left:20%;
        padding-right:20%;
        min-height:110vh;
    }
}

.profile-img-block{
    width:100%;
    text-align: center;
    padding-bottom:5%;
}

@media only screen and (min-width: 850px) {
    .profile-img-block{
        width:35%;
        float:left;
        text-align: center;
        padding-bottom:0%;
    }
}

.image-background{
    width:200px;
    height:200px;
    background-color: rgb(30,42,75);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin:auto;
}

.profile-img{
    width:90%;
    height:90%;
    object-fit: cover;
    border-radius: 50%;
    margin: auto;
}

.profile-caption{
    padding:10px;
}

.profile-text-0{
    width:100%;
    font-size: 18px;
    padding-left:10%;
    padding-right:10%;
}

@media only screen and (min-width: 850px) {
    .profile-text-0{
        width:65%;
        float:left;
        padding:0%;
    }
}

.profile-text-1{
    width:100%;
    float:left;
    padding-left: 10%;
    padding-right: 10%;
    padding-top:5%;
    padding-bottom: 2%;
    font-size: 16px;
}

@media only screen and (min-width: 850px) {
    .profile-text-1{
        padding-left: 7%;
        padding-top:2%;
    }
}

.profile-link{
    width:100%;
    float:left;
    padding-left: 10%;
    text-decoration: underline;
    font-size: 20px;
}

@media only screen and (min-width: 850px) {
    .profile-link{
        padding-left: 7%;
        font-size: 30px;
    }
}

