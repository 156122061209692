
.page-nav{
    width:100%;
    list-style-type: none;
    position:fixed;
    top:0;
    z-index:3;
}

.nav-background{
    display:block;
    float:right;
    padding:10px;
    margin-right:-50px;
    padding-right:50px;
    padding-left:20px;
    opacity: 100%;
    background-color: rgb(14 24 33);
    width:130px;
    height:60px;
}

@media only screen and (min-width: 1440px) {
    .nav-background{
        width:auto;
        height:auto;
        padding-left:10px;
    }
}

.top-links{
    transform: translateX(0px);
    position: relative;
    display:block;
}
.top-links-mob{
    display:none;
}

@media only screen and (max-width: 1440px) {
    .top-links{
        display:none;
    }
    .top-links-mob{
        display:block;
        transform: translateX(500px);
        background-color: rgb(14 24 33);
        width: 200px;
        padding-right: 300px;
        position: absolute;
        top: 0;
        z-index: 1;
    }
}

.nav-link{
    display:inline-block;
    font-size: 25px;
    transform: skew(-25deg);
}

.nav-link a{
    transition: color 0.2s;
}

.nav-link:hover a{
    color: whitesmoke;
    text-decoration: none;
}