

.rel-pos{
    position: relative;
    top:0;
    left:0;
}

.abs-pos{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.fill{
  width:100%;
  height:100%;
}

.clip{
  object-fit: cover;
}

.wrapper{
    height:100%;
    cursor:pointer;
    overflow: hidden;
    z-index: 1;
}

.hover-img{    
    opacity: 0;
}

.wrapper:hover .hover-img{
  opacity: 1;
}

.zoom{
  transition: transform 1.0s ease-out;
}

.wrapper:hover .zoom{
  transform: scale(1.1);
}


.panel{
  height: 90%;
  width: 90%;
  opacity:0;
  transition-delay: 0.1s;
  transition: opacity 0.4s, width 0.4s, height 0.4s;
  background-color: whitesmoke;
}

.wrapper:hover .panel{
  opacity:0.5;
  width:100%;
  height: 100%;
}

.title{
  display: flex;
  justify-content: center;
  align-items: center;
  opacity:0;
  transition-delay: 0.1s;
  transition: opacity 0.4s;
  color: black;
  font-size: xx-large;
}

.genre{
    font-size: large;
    font-style: italic;
    font-weight: bold;
    top: 70px;
}

.wrapper:hover .title{
  opacity:0.5;
}
  
