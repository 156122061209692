
.burger-icon{
    width:45px;
    padding:5px;
    display:inline-block;
    cursor: pointer;
    display:block;
    transform: skew(-25deg);
    z-index: 2;
    position:relative;
}

@media only screen and (min-width: 1440px) {
    .burger-icon{
        display:none;
    }
}

.burger-line{
    width:35px;
    height:5px;
    margin-bottom:6px;
    background-color: rgb(91,126,224);
    transition: background-color 0.2s;
}

.burger-icon:hover .burger-line{
    background-color: whitesmoke;
}