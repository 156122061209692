
.project-background{
    position:relative;
    width:80vw;
    filter:blur(8px);
}

.project-page{
    width: 100vw;
    color: rgb(165 183 234);
    font-family: Raleway;
    overflow-x: hidden;
    position:relative;
    justify-content: center;
    align-items: center;
    background-color: rgb(12 24 33);
}

.project-page-header-wrapper{
    position:relative;
    width:100vw;
    height:50vh;
    overflow:hidden;
}

.project-page-header-background{
    position:absolute;
    top:0;
    left:60%;
    background-color: rgb(12 24 33);
    width:100%;
    height:100%;
    transform:skew(25deg);
    z-index:2;
}
.project-page-header-text{
    display: flex;
    font-size: 30px;
    height:100%;
    width:50%;
    text-align:right;
    justify-content:right;
    margin-top:7vh;
    margin-left:-100px;
    margin-right:auto;
    transform:skew(-25deg);
}

@media only screen and (min-width:800px){
    .project-page-header-text{
    	font-size:40px;
	margin-left:-40px;
	margin-top:20vh;
	margin-right:auto;
	text-align:left;
	justify-content:left;
    }

}

@media only screen and (min-width:1200px){
    .project-page-header-text{
    	font-size:40px;
	margin-left:-40px;
	margin-top:20vh;
	margin-right:auto;
    }
}

@media only screen and (min-width:1440px){
    .project-page-header-text{
    	font-size:60px;
	margin-left:0px;
	margin-top:20vh;
	margin-right:auto;
    }
}


.project-page-header-image{
    position:absolute;
    left:0;
    top:0;
    object-fit:cover;
    width:100%;
    height:100%;
}

@media only screen and (min-width:1440px){
	.project-page-header-image{
		width:70%;
	}

}

.project-page-intro{
    text-align: center;
    justify-content:center;
    font-size: 25px;
    padding:40px;
    position:relative;
    width:100%;
    margin:auto;
}


.project-page-body{
    text-align: center;
    font-size: 20px;
    padding:0px;
    position:relative;
}

.project-link-wrapper{
	display:flex;
	justify-content:center;
	width:100%;
	margin:auto;
}

.project-link{
	width:60px;
	padding:10px;
}

@media only screen and (min-width:1200px){
    .project-page-intro{
    	width:60%;
    }
}


@media only screen and (min-width: 1440px) {
    .project-page-intro{
        padding:40px;
	width:60%;
    }

    .project-page-body{
        padding:40px;
    }
}

.project-page-video-header{
    text-align: center;
    font-size: 30px;
    padding:10px;
    font-weight: bold;
    position:relative;
}

.project-page-video{
    width:100vw;
    margin:auto;
    position:relative;
    padding:0px;
}

@media only screen and (min-width: 850px) {
    .project-page-video{
        padding:30px;
    }
}

@media only screen and (min-width: 1200px) {
    .project-page-video{
        width:80vw;
    }
}

@media only screen and (min-width: 1440px) {
    .project-page-video{
        width:50vw;
    }
}

.project-page-image-block{
    width:100vw;
    padding-top:0px;
    position:relative;
    line-height: 1.2;
    margin:auto;
    padding-bottom: 0px;
}

@media only screen and (min-width: 850px) {
    .project-page-image-block{
        width:80vw;
    }
}

@media only screen and (min-width: 1200px) {
    .project-page-image-block{
        width:70vw;
    }
}
