
.contact-page{
    width:100%;
    min-height:70vh;
    background-color: rgb(14 24 33);
    position: relative;
    z-index:2;
}

.contact-body{
    width:100%;
    padding-left:10%;
    padding-right:10%;
    padding-top: 30px;
    padding-bottom:20px;
    margin:auto;
}
@media only screen and (min-width: 850px) {
    .contact-body{
        padding-left:20%;
        padding-right:20%;
    }
}

@media only screen and (min-width: 1440px) {
    .contact-body{
        padding-left:30%;
        padding-right:30%;
    }
}

.contact-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    padding:20px;
}

.contact-intro{
    font-size: 20px;
    padding-bottom: 0px;
}

.contact-link{
    width:40px;
    padding-top:0px;
    padding-right:5px;
}


.contact-thanks{
    font-size: 25px;
    padding: 30px;
    padding-left:0px;
}
