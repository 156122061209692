
.image-link-array{
    width:100%;
    list-style-type: none;
    padding:0;
    margin:0;
    line-height: 0;
    position:relative;
    display:block;
    height: auto;
    overflow: auto;
    z-index: 10;
}

.link-element{    
    display:inline-block;
    height:50vh;
}

.link-1, .link-2, .link-3, .link-4, .link-5, .link-6 {
    width:100%;
}

@media only screen and (min-width: 850px) {
    .link-2, .link-3, .link-4, .link-5, .link-6 {
        width:50%;
    }
}

@media only screen and (min-width: 1440px) {
    .link-3{
        width:33.33%;
    }
    
    .link-4, .link-5, .link-6{
        width:25%;
    }
}

@media only screen and (min-width: 2561) {

    .link-5{
        width:20%;
    }
    
    .link-6{
        width:16.67%;
    }
}