.loading-page{
    width: 100vw;
    height:100vh;
    background-color: rgb(14 24 33);
}

.loading-text{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    color:rgb(91,126,224);
    text-align: center;
    font-family: Raleway;
}

.loading-image{

}
