
.multi-project-page{
    width: 100vw;
    color: rgb(165,183,234);
    font-family: Raleway;
    overflow-x: hidden;
    position:relative;
    justify-content: center;
    align-items: center;
    background-color: rgb(12 24 33);
}

.multi-project-page-video-block{
    width:100vw;
    height:170vw;
    margin: auto;
    position: relative;
    
}

@media only screen and (min-width: 850px) {
    .multi-project-page-video-block{
        height:100vw;
    }
}

@media only screen and (min-width: 1200px) {
    .multi-project-page-video-block{
        width:80vw;
        height:60vh;
    }
}

.multi-project-page-video-background{
    height:200vw;
    width:100%;
    background-color: whitesmoke;
    opacity: 0.1;
    position: absolute;
    top:0;
}

.mp-bg-left{
    left:0;
}

.mp-bg-right{
    right:0;
}

@media only screen and (min-width: 1200px) {
    .multi-project-page-video-background{
        height:100%;
        width:200%;
    }
    .mp-bg-right{
        right:0;
    }
}

.multi-project-page-video-title{
    position: absolute;
    top:60vw;
    font-size: 30px;
    font-weight: bold;
    width:45%;
}

.mp-ttl-left{
    left:4%;
}

.mp-ttl-right{
    left:4%;
}

@media only screen and (min-width: 1200px) {
    .multi-project-page-video-title{
        top:5%;
    }
    .mp-ttl-left{
        left:54%;
    }
}


.multi-project-page-video-caption{
    position: absolute;
    top:75vw;
    font-size: 20px;
    margin-right:50px;
    width:45%;
}

.mp-cp-left{
    left:4%;
}

.mp-cp-right{
    left:4%;
}

@media only screen and (min-width: 800px) {
    .multi-project-page-video-caption{
        top:65vw;
    }
}


@media only screen and (min-width: 1200px) {
    .multi-project-page-video-caption{
        top:15%;
	font-size:15px;
    }
    .mp-cp-left{
        left:54%;
    }
}

@media only screen and (min-width: 1440px){
    .multi-project-page-video-caption{
    	font-size:20px;
    }

}


.multi-project-page-video{
    width:100vw;
    position:absolute;
    top:0;
    padding:0px;
}

.mp-vid-left{
    left:0;
}

.mp-vid-right{
    right:0;
}

@media only screen and (min-width: 850px) {
    .multi-project-page-video{
        
    }
}

@media only screen and (min-width: 1200px) {
    .multi-project-page-video{
        width:40vw;
    }
}

@media only screen and (min-width: 1440px) {
    .multi-project-page-video{
        width:40vw;
    }
}
