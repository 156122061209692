
.expandable-image-link{
    width:100%;
    height:30vh;
    display:inline-block;
    position: relative;
    margin: auto;
    overflow: hidden;
}

@media only screen and (min-width: 450px) {
  .expandable-image-link{
    width:50%;
  }
}

@media only screen and (min-width: 1440px) {
  .expandable-image-link{
    width:33%;
    padding:15px;
  }
}

.expandable-image-link:focus{
  overflow: visible;
  cursor: default;
}

html:has(.expandable-image-link:focus) {
  position:static;
}

.expandable-image-link:not(.expandable-image-link:focus):hover{
    transform: scale(1.02);
    z-index:1;
}


.expandable-image-link .expandable-image-magnifying-glass{
  width:100%;
  opacity:0;
  transition: opacity 0.4s;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: xx-large;
}

.expandable-image-link:hover .expandable-image-magnifying-glass{
  opacity:1;
}



.expandable-image-link .expandable-image-panel{
    width:100%;
    opacity:0;
    transition: opacity 0.2s;
    background-color: black;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
  
  .expandable-image-link:hover .expandable-image-panel{
    opacity:0.5;
  }

  .expandable-image-link:focus .expandable-image-panel{
    opacity:1;
    width:100vw;
    height:100vh;
    position:fixed;
    z-index:2;
  }


.expandable-image-img{
  width:100%;
  height:auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color:#0c1821;
  z-index:0;
}

.expandable-image-link:focus .expandable-image-img{
  position: fixed;
  height:100vh;
  z-index:3;
  background-size: contain;
}

.expandable-image-caption-background{
  display:none;
  position:absolute;
  height:30%;
  width:100%;
  z-index:4;
  background-color: #0c1821;
  opacity: 0.8;
}

@media only screen and (min-width: 850px) {
  .expandable-image-caption-background{
    height:30%;
    width:60%;
  }
}

.caption-bg-left{
  left:0;
  bottom:0;
  margin-left:-60px;
}

.caption-bg-right{
  right:0;
  bottom:0;
  margin-right:-60px;
}

@media only screen and (min-width: 850px) {
  .caption-bg-left{
    transform:skew(20deg);
  }
  .caption-bg-right{
    transform:skew(-20deg);
  }
}

.expandable-image-link:focus .expandable-image-caption-background{
  display: block;
}

.expandable-image-caption{
  display:none;
  position:absolute;
  bottom:0;
  text-align: left;
  padding:40px;
  height:30%;
  width:100%;
  z-index:5;
  font-size: 20px;
  color: rgb(91,126,224);
}


.caption-left{
  left:0%;
}

.caption-right{
  left:0%;
}

@media only screen and (min-width: 850px) {
  .expandable-image-caption{
  	width:40%;
  }

  .caption-left{
    left:5%;
  }
  .caption-right{
    left:40%;
  }
}

@media only screen and (min-width: 1440px) {
  .caption-left{
    left:10%;
  }
  .caption-right{
    left:50%;
  }
}

@media only screen and (min-width: 1920px) {
  .caption-left{
    left:10%;
  }
  .caption-right{
    left:50%;
  }
}

.expandable-image-link:focus .expandable-image-caption{
  display: block;
}

.expandable-image-link .cross-icon{
  display: none;
  position:fixed;
  top:5%;
  right:2%;
}

.expandable-image-link:focus .cross-icon{
  display: block;
}

.expandable-image-img:has(.cross-icon:focus){
  position:absolute;
  width:100%;
  height:auto;
}
